import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setShowSubscriptionStatusRefreshModal, setShowSubscriptionModal, getUserStatus } from "../UserAuthentication/AuthenticationSlice";
import { resetOnboardingStates } from "../onboarding/onboardingSlice";
import TemplatePreview from "./TemplatePreview/index.js";

import { useUser } from "@clerk/clerk-react"

import {
    sendMessage,
    enableNewChat,
    disableNewChat,
    setThreadId,
    getThread,
    resetChatPage,
    updateViewedChats,
    resetChangesAccepted,
    // updateMessagingWithAiMessage,
    convertDocument,
    // getAdditionalMessages,
    // updateMessagingDisabled,
    createNewMessage,
    updateNewThreadCreated,
    clearEditTemplateLocal,
    // setLastConversationalMessage,
} from "./chatSlice";
import { useNavigate } from "react-router-dom";
import { setImage, setImageLawyer } from "../UserAuthentication/AuthenticationSlice";

import SideNav from "./SideNav.js";
import ChatLayout from "../../components/chat/ChatLayout";
import {
    List,
    Paperclip,
    Spinner,
} from "@phosphor-icons/react";

import ArrowCircleUpColored from "../../images/ArrowCircleUpColored.svg";

// import Thread from "../../components/chat/Thread";
import ChatHome from "../../components/chat/ChatHome";
// import { collection, onSnapshot, query, where, orderBy } from "firebase/firestore";
// import { firestore } from "../../firebase";
import IHLogo from "../../images/i-h-logo.svg";

import {
    // getAllThreadsV2,
    getTitle,
    // removeDeletedThreadFromList,
    // updateThreadTitleLocal,
    // addUnreadAskThread,
    // addUnreadDraftThread,
    deleteUnreadAskThread,
    deleteUnreadDraftThread,
    addNewThreadLocal,
    generateSharedId,
    resetThreadStates,
    // markThreadRead,
} from "./threadsSlice";

import TextArea from "antd/es/input/TextArea";
import ImageUploader from "../../components/chat/ImageUploader";
import { useFavicon } from "../../components/chat/useFavicon";
import toast, { Toaster } from "react-hot-toast";
import SubscriptionModal from "../../components/chat/Subscription.js";
import RetainerModal from "../../components/chat/RetainerModals.js"
import SubscriptionStatusRefreshModal from "./Modals/SubscriptionStatusRefreshModal/index.js";

import FilePreview from "./FilePreview/index.js";
import useValidatePageAccess from "../../hooks/useValidatePageAccess.js";

// import TextInput from "./TextInput/index.js";
const notify = () => toast.success("Copied shared conversation URL to clipboard!");


function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie =
        name + "=" + (value || "") + expires + "; path=/;";
}


function getCookie(name) {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split("=");
        if (cookie[0] === name) {
            return decodeURIComponent(cookie[1]);
        }
    }
    return null; // Return null if cookie with given name is not found
}

function deleteCookie(name) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}


const AskPage = forwardRef((props, ref) => {
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [extractedMessage, setExtractedMessage] = useState("");
    const [messageList, setMessageList] = useState([]);
    // const [messageId, setMessageId] = useState("");
    // const [errorMessage, setErrorMessage] = useState("");
    const [copiedItem, setCopiedItem] = useState("");
    // const [legalReviewDisabled, setLegalReviewDisabled] = useState(false);
    // const [legalReviewAvailable, setLegalReviewAvailable] = useState(false);
    const [listenerEnabledForThread, setListenerEnabledForThread] = useState(null);
    const [basicModal, setBasicModal] = useState(false);
    const [basicModal2, setBasicModal2] = useState(false);
    const [mode, setMode] = useState("Ask");
    const [imageUploader, setImageUploader] = useState(false);
    // const [showOnboardingForm, setShowOnboardingForm] = useState(false);
    const [notificationCount, setNotificationCount] = useFavicon("");
    const [previousCount, setPreviousCount] = useState(0);
    const [title] = useState("InHouse");
    const [shareLinkModalOpen, setShareLinkModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sharedId, setSharedId] = useState("");
    const [selectedThread, setSelectedThread] = useState(null);
    const [isNavVisible, setIsNavVisible] = useState(false);
    const [userAction, setUserAction] = useState("new-thread");
    const [retainermodalOpen, setRetainerModalOpen] = useState(false);
    // const [showSubscriptionStatusRefreshModal, setShowSubscriptionStatusRefreshModal] = useState(false);
    const [threadList, setThreadList] = useState([]);
    const [startDate, setStartDate] = useState(new Date('2024-07-24T23:59:00'))
    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);


    const image = useSelector((state) => state.auth.image);
    // const imageLawyer = useSelector((state) => state.auth.imageLawyer)
    const messages = useSelector((state) => state.chat.messages);
    const sendMessageStatus = useSelector((state) => state.chat.sendMessageStatus);
    const threadId = useSelector((state) => state.chat.threadId);
    const getThreadStatus = useSelector((state) => state.chat.getThreadStatus);
    const inhouseUser = useSelector((state) => state.auth.user);
    const lawyerDetails = useSelector((state) => state.auth.lawyerInfo);
    const messagingDisabled = useSelector((state) => state.chat.messagingDisabled);

    const askThreads_status = useSelector((state) => state.threads.askThreads_status);

    const requestLegalReviewStatus = useSelector((state) => state.chat.requestLegalReviewStatus);
    const feedbackStatus = useSelector((state) => state.chat.feedbackStatus);
    const [threadsCreatedAfterDate, setThreadsCreatedAfterDate] = useState([null]);

    const askThreadsList = useSelector((state) => state.threads.askThreadsList);
    const draftThreadsList = useSelector((state) => state.threads.draftThreadsList);
    const newThreadCreated = useSelector((state) => state.chat.newThreadCreated);
    const unreadAskThreads = useSelector((state) => state.threads.unreadAskThreads);
    const unreadDraftThreads = useSelector((state) => state.threads.unreadDraftThreads);
    const shareThreadStatus = useSelector((state) => state.threads.shareThreadStatus);

    const textExtractionStatus = useSelector((state) => state.chat.textExtractionStatus);
    const textExtractionTrimmed = useSelector((state) => state.chat.textExtractionTrimmed);
    const extractedText = useSelector((state) => state.chat.extractedText);
    const lastMessageId = useSelector((state) => state.chat.lastMessageId);
    const notUpdatedThreads = useSelector((state) => state.chat.notUpdatedThreads);

    const editTemplate = useSelector((state) => state.chat.editTemplate);
    // const failedDueTo418 = useSelector((state) => state.auth.failedDueTo418);
    const showSubscriptionStatusRefreshModal = useSelector((state) => state.auth.showSubscriptionStatusRefreshModal);
    const subscriptionType = useSelector((state) => state.auth.subscriptionType);
    // const subscriptionStatusFetch = useSelector((state) => state.auth.subscriptionStatusFetch);
    const userMessageCount = useSelector((state) => state.chat.userMessageCount);
    const getUserDetailsStatus = useSelector((state) => state.auth.getUserDetailsStatus);

    const BASE_URL = process.env.REACT_APP_URL;

    const messageListRef = useRef(null);
    // const showForm = JSON.parse(localStorage.getItem("showForm"));

    const displayName = inhouseUser?.organization_name ? inhouseUser?.organization_name : (inhouseUser?.first_name ? inhouseUser?.first_name : inhouseUser?.user_email?.split("@")[0]);
    // const showTutorial = localStorage.getItem("showTutorial");

    let { chatId } = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    const sub_id = queryParams.get('sub_id');
    // const pagestate = queryParams.get('state');
    // const invoice_id = queryParams.get('invoice_id');

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const starterMessage = localStorage.getItem("starterMessage");
    // const messageType = localStorage.getItem("messageType");


    const { isLoaded, isSignedIn, user } = useUser();

    useEffect(() => {
        if (isSignedIn) {
            var isCountryCodeAmerica = ((Intl.DateTimeFormat().resolvedOptions().timeZone) && Intl.DateTimeFormat().resolvedOptions().timeZone.includes('America'));

            if (isCountryCodeAmerica) {
                window.analytics.track("start_hotjar_recording", {
                    "user_email": inhouseUser?.user_email,
                    "user_id": inhouseUser?.user_id,
                    "event": "start tracking on hotjar"
                })
            }
        }
    }, [isSignedIn, inhouseUser?.user_email]);


    useEffect(() => {
        // set mode based on the location.
        // get thread details if the user clicks on any particular thread
        // unread-thread management part 2
        if (window.location === "/ask") {
            window.analytics.page('ask_homepage_visited')
        }
        dispatch(resetChatPage({ newThreadCreated: newThreadCreated }));
        setFile(null);
        setFilePreview(null);
        setExtractedMessage("");
        dispatch(resetThreadStates());
        dispatch(resetOnboardingStates())
        if (location.pathname.indexOf("/ask") > -1) {
            setMode("Ask");
            setThreadList(askThreadsList);
        } else if (location.pathname.indexOf("/draft") > -1) {
            setMode("Draft");
            setThreadList(draftThreadsList);
        }
        if (location.pathname !== "/ask" && location.pathname !== "/draft") {
            if (!newThreadCreated) {
                dispatch(getThread(chatId));
            }
            dispatch(updateViewedChats(chatId));
            // setNewTitle("");
        }
    }, [location.pathname]); // eslint-disable-line


    useEffect(() => {
        if (user) {
            if (!user?.firstName || !user?.unsafeMetadata?.organization_name) {
                navigate('/onboarding')
            }
        }
    }, [inhouseUser, user]);


    useEffect(() => {
        setThreadList([...askThreadsList]);
    }, [askThreadsList, askThreads_status]); // eslint-disable-line

    // unread-threads management part 1
    useEffect(() => {
        setIsNavVisible(false);
        if (!starterMessage) {
            setMessage("");
        }
        if (chatId) {
            dispatch(clearEditTemplateLocal());
            if (!newThreadCreated) {
                setMessageList([]);
            }
            dispatch(updateNewThreadCreated(false));
            if (location.pathname.indexOf("ask") !== -1) {
                if (unreadAskThreads.indexOf(chatId) !== -1) {
                    dispatch(deleteUnreadAskThread(chatId));
                    dispatch(deleteUnreadDraftThread(chatId));
                }
            }
            if (location.pathname.indexOf("draft") !== -1) {
                if (unreadDraftThreads.indexOf(chatId) !== -1) {
                    dispatch(deleteUnreadAskThread(chatId));
                    dispatch(deleteUnreadDraftThread(chatId));
                }
            }
        }
    }, [chatId, location.pathname]); // eslint-disable-line

    useEffect(() => {
        if (starterMessage) {
            setMessage(starterMessage);
            deleteCookie("starter-message");
            localStorage.removeItem("starterMessage");
        }
    }, []);

    useEffect(() => {
        if (sub_id) {
            dispatch(setShowSubscriptionStatusRefreshModal(true))
        }
        // eslint-disable-next-line
    }, [sub_id, inhouseUser]);

    useEffect(() => {
        if (notUpdatedThreads.length > 0) {
            notUpdatedThreads.map((thread) => {
                dispatch(getTitle(thread));
            });
        }
    }, [notUpdatedThreads]);


    // set image based on the user profile image
    useEffect(() => {
        if (inhouseUser?.profileImage) {
            dispatch(setImage(inhouseUser.profileImage))
        } else {
            const firstNameInitial = inhouseUser?.first_name ? inhouseUser.first_name[0].toUpperCase() : "";
            const lastNameInitial = inhouseUser?.last_name ? inhouseUser.last_name[0].toUpperCase() : "";
            const name = inhouseUser?.first_name ? firstNameInitial + lastNameInitial : inhouseUser?.user_email?.[0]?.toUpperCase();
            // const path = `../../images/white/${name}.png`;

            dispatch(setImage(name))
        }
    }, [inhouseUser]);

    // set lawyer-image based on the lawyer profile image
    useEffect(() => {
        if (lawyerDetails?.profileImage) {
            dispatch(setImageLawyer(lawyerDetails.profileImage))
        } else {
            const name = lawyerDetails?.first_name
                ? lawyerDetails.first_name[0].toUpperCase()
                : lawyerDetails?.user_email?.[0]?.toUpperCase();
            const path = `../../images/white/${name}.png`;
            dispatch(setImageLawyer(path))
        }
    }, [lawyerDetails]);


    useEffect(() => {
        if (file) {
            const formData = new FormData();
            formData.append("file", file);
            dispatch(convertDocument(formData));

            setImageUploader(false);
        }
    }, [file]);

    useEffect(() => {
        if (textExtractionTrimmed) {
            toast.error(`The document you uploaded was very large. Only first ${textExtractionTrimmed} words of documents will be used.`)
        }
    }, [textExtractionTrimmed]);

    useEffect(() => {
        if (file && extractedText && textExtractionStatus === "success") {
            setExtractedMessage(extractedText);
            setFilePreview({
                name: file?.name,
                text: extractedText,
            });
        }
    }, [extractedText, textExtractionStatus, file]);


    useEffect(() => {
        if (newThreadCreated === true) {
            window.analytics.track('New Thread created', {
                title: 'New thread',
                url: window.location.href,
                path: window.location.pathname || window.location.path,
                user_email: inhouseUser?.user_email
            });
            if (threadList.length === 0) {
                window.analytics.track('First Message Sent', {
                    title: 'First Message Sent',
                    url: `${window.location.origin}/${mode.toLocaleLowerCase()}/${threadId}`,
                    path: window.location.pathname || window.location.path,
                    user_email: inhouseUser?.user_email
                });
            }

            dispatch(
                createNewMessage({
                    user: inhouseUser,
                    message_type: "conversation",
                    text: message,
                    thread_id: threadId,
                    created_at: new Date(),
                    feedback_type: null,
                    sequence_number: 1,
                    attachment_names: filePreview?.name ? [filePreview?.name] : null,
                })
            );
            navigate(`/${mode.toLocaleLowerCase()}/${threadId}`);
            let title = message.substring(0, 50);
            if (!title) {
                title = "New Thread";
            }
            setMessage("");
            var data = {
                id: threadId,
                title: title,
                title_source: null,
                created_at: new Date(),
                user_id: inhouseUser.user_id,
                legal_review_requested: null,
                legal_review_submitted: null,
                messaging_disabled: false,
                legal_review_viewed: null,
                shared_id: "",
                attachment_names: filePreview?.name ? [filePreview?.name] : null,
            };
            dispatch(addNewThreadLocal({ mode: mode.toLowerCase(), thread: threadId, data: data }));
        }
    }, [newThreadCreated, threadId]); // eslint-disable-line


    useEffect(() => {
        if (requestLegalReviewStatus === "success" || requestLegalReviewStatus === "failed") {
            window.analytics.track("Legal Review Requested Successfully", { user_email: inhouseUser?.user_email });
            // setEmbedLink("");
            setRetainerModalOpen(false);
        }
    }, [requestLegalReviewStatus]);

    const updateMessage = (messageText) => {
        setMessage(messageText);
    };

    if (chatId === undefined) {
        chatId = null;
        dispatch(enableNewChat());
    } else {
        dispatch(disableNewChat());
        dispatch(setThreadId(chatId));
    }

    useEffect(() => {
        var count = 0;
        threadList.map((thread) => {
            if (new Date(thread.created_at) > startDate) {
                count += 1
            }
        });
        setThreadsCreatedAfterDate(count);
    }, [threadList]);


    const handleSend = ({ messageInput }) => {
        // check if subscription type is free-plan. if yes, then check if the user has one thread active.
        // if yes, then show the subscription modal
        if (!chatId) {
            // if the user is creating a new thread, then check if the user has any existing threads
            // only subscribed users can create more than one threads
            if (["free", null, "", "Free-Plan-USD-Monthly", "trial_expired", "subscription_expired"].indexOf(subscriptionType) !== -1 && !chatId) {
                var threadsCount = threadsCreatedAfterDate;

                // if (threadsCount >= 1) {
                setUserAction("new-thread");
                dispatch(setShowSubscriptionModal(true));
                setCookie("starter-message", messageInput, 2);
                window.analytics.track("subscription popup triggered", { "action": "new thread", "chatId": "new thread", user_email: inhouseUser?.user_email });
                return;
                // }
            }
        }
        else {
            // if the user is sending message in an existing thread, then check if the user has any existing threads
            // only subscribed users can create more than one threads
            if (["free", null, "", "Free-Plan-USD-Monthly", "trial_expired", "subscription_expired"].indexOf(subscriptionType) !== -1 && chatId) {
                // if (true) {
                // var threadsCount = threadsCreatedAfterDate;
                // if (threadsCount > 1) {
                setUserAction("new-thread");
                dispatch(setShowSubscriptionModal(true))
                window.analytics.track("subscription popup triggered", { "action": "new message in existing thread", "chatId": chatId, user_email: inhouseUser?.user_email });
                return;
                // }
            }
        }
        dispatch(resetChangesAccepted());
        const creation_time = new Date().toISOString();
        scrollContainerToBottom();
        if (chatId && (location.pathname !== "/ask" || location.pathname !== "/draft")) {
            dispatch(
                sendMessage({
                    message_text: messageInput,
                    thread_id: threadId,
                    mode: mode.toLowerCase(),
                    creation_time,
                    message_type: "conversation",
                    attachment: filePreview ? filePreview : null,
                })
            );
            // console.log(userMessageCount + 1)
            window.analytics.track("New message in a thread", {
                message_text: messageInput,
                thread_id: threadId,
                user_message_count: userMessageCount + 1,
                user_email: inhouseUser?.user_email
            });
        } else {
            if (editTemplate) {
                dispatch(
                    sendMessage({
                        message_text: messageInput,
                        attachment: filePreview ? filePreview : null,
                        template_id: editTemplate ? editTemplate.id : null,
                    })
                );
            } else {
                dispatch(
                    sendMessage({
                        message_text: messageInput,
                        thread_id: null,
                        mode: mode.toLowerCase(),
                        creation_time,
                        message_type: "conversation",
                        attachment: filePreview ? filePreview : null,
                    })
                );
            }
        }
        deleteCookie("starter-message");
    };

    useEffect(() => {
        // Scroll to the bottom when messageList or sendMessageStatus changes
        scrollContainerToBottom();
    }, [messages, sendMessageStatus, messageList]);

    const scrollContainerToBottom = () => {
        if (messageListRef.current) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    };


    useEffect(() => {
        if (feedbackStatus === "success") {
            toggleOpen();
            alert("Your feedback has been recorded. Thank you for your feedback.");
        }
    }, [feedbackStatus]);

    useEffect(() => {
        if (sendMessageStatus === "success") {
            setMessage("");
            setExtractedMessage("");
            setFile(null);
            setFilePreview(null);
        }
    }, [sendMessageStatus]);

    const toggleOpen = () => setBasicModal(!basicModal);
    const toggleOpen2 = () => setBasicModal2(!basicModal2);


    // useEffect(() => {
    //     if (user) {
    //         var cid = chatId ? chatId : threadId;
    //         setListenerEnabledForThread(cid);
    //         const collectionRef = collection(firestore, `frontend-updates-client/${user.user_email}/new-messages`);
    //         var date = new Date();
    //         var q = query(collectionRef, where("timestamp", ">", date.toISOString()), orderBy("timestamp", "asc"));
    //         // console.log(
    //         //   "adding listener for thread " + cid + " timestamp: " + date.toISOString() + " time: " + new Date().toISOString()
    //         // );

    //         const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //             querySnapshot.docChanges().forEach((doc) => {
    //                 // console.log(doc.doc.data());
    //                 var thread_id = doc.doc.data()["thread_id"];
    //                 var aiMessage = doc.doc.data()["ai_message"];
    //                 var isMessagingDisabled = doc.doc.data()["messaging_disabled"];
    //                 var newMessageAvailable = doc.doc.data()["new_message_available"];
    //                 // var isNewMessageAvailable = doc.doc.data()["new_message_available"];
    //                 var notificationMode = doc.doc.data()["mode"];
    //                 if (doc.type === "added") {
    //                     if (thread_id !== cid) {
    //                         if (notificationMode === "ask") {
    //                             dispatch(addUnreadAskThread(thread_id));
    //                         } else if (notificationMode === "draft") {
    //                             dispatch(addUnreadDraftThread(thread_id));
    //                         }
    //                     } else if (lastMessageId && cid && thread_id === cid && getThreadStatus === "success") {
    //                         // if this is the current thread
    //                         if (aiMessage) {
    //                             dispatch(updateMessagingWithAiMessage({ message: aiMessage }));
    //                             localStorage.setItem("newAiMessage", aiMessage.id);
    //                         } else if (newMessageAvailable) {
    //                             dispatch(getAdditionalMessages({ chatId: cid, messageId: lastMessageId }));
    //                         }
    //                         if (aiMessage && aiMessage.message_type === "conversation") {
    //                             dispatch(setLastConversationalMessage(aiMessage.id));
    //                         }
    //                         dispatch(updateMessagingDisabled(isMessagingDisabled));
    //                         // if this is the current thread, mark thread as read
    //                         dispatch(markThreadRead({ threadId: thread_id }));
    //                     }
    //                 }
    //             });
    //         });
    //         return () => {
    //             unsubscribe();
    //         };
    //     }
    //     // getThreadStatus == list-messages fetch status
    // }, [getThreadStatus, user?.user_email]); // eslint-disable-line

    useEffect(() => {
        if (unreadAskThreads.length > 0 || unreadDraftThreads.length > 0) {
            setNotificationCount(unreadAskThreads.length + unreadDraftThreads.length);
        } else {
            setNotificationCount(0);
        }
    }, [unreadAskThreads, unreadDraftThreads]);

    useEffect(() => {
        let intervalId;

        if (notificationCount > previousCount) {
            let flipTitle = true;
            intervalId = setInterval(() => {
                document.title = flipTitle ? `(${notificationCount}) ${title}` : "New Message";
                flipTitle = !flipTitle; // Toggle between the two titles
            }, 1000); // Change the title every second

            // Stop the interval after 10 changes (5 cycles of 2 seconds)
            setTimeout(() => {
                clearInterval(intervalId);
                // Reset to original title
                document.title = `(${notificationCount > 0 && notificationCount}) ${title}`;
            }, 1000 * 10); // 10 seconds
        } else {
            document.title = notificationCount > 0 ? `(${notificationCount}) ${title}` : title;
        }

        // Update the previous count after any change in notificationCount
        setPreviousCount(notificationCount);

        // Cleanup interval on component unmount or when count changes
        return () => {
            clearInterval(intervalId);
        };
    }, [notificationCount, title]);

    const handleCopy = (url) => {
        navigator.clipboard
            .writeText(url)
            .then(() => {
                // setShareLinkModalOpen(false);
                notify();
                setRetainerModalOpen(false);
            })
            .catch((err) => console.error("Error copying text: ", err));
    };

    useEffect(() => {
        setSharedId(selectedThread?.shared_id);
    }, [selectedThread?.shared_id]);

    useEffect(() => {
        if (shareThreadStatus === "success") {
            setLoading(false);
            setShareLinkModalOpen(false);
            // handleCopy(`${BASE_URL}/shared/${shareThreadId}`);
            dispatch(resetThreadStates());
        } else if (shareThreadStatus === "failed") {
            setLoading(false);
        }
    }, [shareThreadStatus]);

    const getSharedId = (chatId) => {
        dispatch(generateSharedId({ threadId: chatId, mode: mode.toLowerCase() }));
    };

    useValidatePageAccess();

    return (
        <>
            {isLoaded ? (
                <ChatLayout>
                    <Toaster />



                    <section className="chat-body h-full w-full flex ">
                        <div className="relative flex fex-row  w-full h-full">
                            <button
                                className="md:hidden block hamburger-button absolute left-0 top-4 z-40"
                                onClick={() => setIsNavVisible(!isNavVisible)}
                            >
                                <List size={32} onClick={() => setIsNavVisible(!isNavVisible)} />
                            </button>
                            {isNavVisible && (
                                <div className="fixed inset-0 bg-black bg-opacity-50 z-30" onClick={() => setIsNavVisible(false)}></div>
                            )}
                            <SideNav mode={mode} chatId={chatId}
                                newThreadCreated={newThreadCreated}
                                displayName={displayName}
                                image={image}
                                isNavVisible={isNavVisible}
                                subscriptionType={subscriptionType}
                            />

                            <div className="relative h-full overflow-y-scroll  w-full flex flex-col justify-between items-center md:px-0 px-2">
                                {/* 4 cases:
                                    1. chat id is none
                                        dynamic messages
                                    2. No messages in theead and resources are being fetched: messages.length == 0 and status = "loading"
                                        loading
                                    3. No messages in the thread event after the resources are fetched: messages.length == 0 and status = "success"
                                        no messages to show
                                    4. Messages are present in the thread: messages.length > 0 and status = "success"
                                        show messages
                                    */}

                                <>


                                    {!chatId ? (
                                        // case 1
                                        <div className="h-[95%] w-full max-w-[913px] overflow-y-scroll">
                                            {sendMessageStatus === "loading" ? (
                                                <div className="h-full w-full  m-auto pt-[50px] overflow-y-scroll">
                                                    <div className="w-full h-full flex justify-center items-center">
                                                        {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
                                                        <div class="rotating-cursor"></div>
                                                    </div>
                                                </div>
                                            ) : (
                                                // !newThreadCreated && (
                                                //   <div className="w-full h-full flex flex-col justify-center items-center gap-[70px] ">
                                                //     <ChatHome updateMessage={updateMessage} handleSend={handleSend} />
                                                //   </div>
                                                // )
                                                !newThreadCreated && (
                                                    <div className={`flex flex-col items-center w-full h-full ${editTemplate ? 'justify-end' : 'justify-around'}`}>
                                                        {!editTemplate ? (
                                                            <div className="flex flex-col items-center justify-center gap-8">
                                                                <img className="mt-6" width={49} height={53} src={IHLogo} alt="Inhouse Logo" />
                                                                <p className="text-black text-[24px] md:text-[42px] font-[800] leading-[1] ">
                                                                    {`Hi${user?.fullName ? " " + user?.fullName : ""}, how can we help you today? `}
                                                                </p>
                                                            </div>
                                                        ) : <></>}
                                                        <div className={`relative chat-inputs-container mt-5 flex flex-col w-full max-w-[913px] mb-2 overflow-hidden gap-[10px] items-end ${filePreview && 'min-h-[188px]'}`}>
                                                            {file && textExtractionStatus === "loading" ? (
                                                                <div className="loading-response w-full h-full flex justify-center items-center">
                                                                    <div class="rotating-cursor mt-6"></div>
                                                                </div>
                                                            ) : filePreview ? (
                                                                <div className="w-full">
                                                                    <FilePreview
                                                                        file={filePreview}
                                                                        onDelete={() => {
                                                                            setFile(null);
                                                                            setFilePreview(null);
                                                                            setExtractedMessage("");
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : null}

                                                            {/* {textExtractionStatus === "loading" ? (
                            <div className="loading-response w-full h-full flex justify-center items-center">
                              <div class="rotating-cursor mt-6"></div>
                            </div>
                          ) : ( */}
                                                            {editTemplate && (
                                                                <div className="w-full">
                                                                    <TemplatePreview
                                                                        file={editTemplate}
                                                                        onDelete={() => {
                                                                            dispatch(clearEditTemplateLocal());
                                                                        }}
                                                                    />
                                                                </div>
                                                            )}

                                                            <TextArea
                                                                variant="borderless"
                                                                className="chat-input-text-area flex w-full flex-grow items-center"
                                                                placeholder={
                                                                    !messagingDisabled ? "I am your legal assistant, powered by advanced language models trained on a unique lawyer curated knowledge base. Please describe your legal needs in detail." : "This chat is closed"
                                                                }
                                                                value={message}
                                                                onChange={(e) => {
                                                                    setMessage(e.target.value);
                                                                    setErrorMessage("");
                                                                }}
                                                                autoSize={{ minRows: chatId ? 1 : 2, maxRows: chatId ? 8 : 4 }}
                                                                disabled={
                                                                    sendMessageStatus === "loading" ||
                                                                    textExtractionStatus === "loading" ||
                                                                    messagingDisabled ||
                                                                    getThreadStatus === "loading"
                                                                }
                                                                onKeyDown={(e) => {
                                                                    if (
                                                                        e.key === "Enter" &&
                                                                        (e.ctrlKey || e.metaKey) &&
                                                                        !e.shiftKey &&
                                                                        (e.target.tagName !== "TEXTAREA" || document.activeElement === e.target)
                                                                    ) {
                                                                        handleSend({ messageInput: message });
                                                                    }
                                                                }}
                                                            />

                                                            <div className="flex items-center justify-between w-full">
                                                                <button
                                                                    className="btn  btn-sm outline-[0px] border-[0px] group"
                                                                    onClick={() => {
                                                                        setImageUploader(true);
                                                                    }}
                                                                    disabled={
                                                                        sendMessageStatus === "loading" ||
                                                                        textExtractionStatus === "loading" ||
                                                                        messagingDisabled
                                                                    }
                                                                    id="send-message-button"
                                                                >
                                                                    {/* chat home screen */}
                                                                    <Paperclip size={24} className="group-hover:text-[#5E59FF]" />
                                                                </button>
                                                                <button
                                                                    className="btn  btn-sm outline-[0px] border-[0px] group"
                                                                    onClick={() => {
                                                                        handleSend({ messageInput: message });
                                                                    }}
                                                                    disabled={
                                                                        sendMessageStatus === "loading" ||
                                                                        textExtractionStatus === "loading" ||
                                                                        messagingDisabled ||
                                                                        message === ""
                                                                    }
                                                                    id="send-message-button"
                                                                >
                                                                    <img height={24} width={24} src={ArrowCircleUpColored} alt="send message" />

                                                                    {/* <img height={24} width={24} src={ArrowCircleUpColored} alt="send message" /> */}
                                                                    {/* <ArrowCircleUp size={24} /> */}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {/* <p className="absolute bottom-1 right-1 text-[12px]">ctrl/cmd + enter</p> */}
                                                        {!editTemplate && (
                                                            <div className="w-full flex flex-col justify-center items-center">
                                                                <ChatHome updateMessage={updateMessage} handleSend={handleSend} />
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    ) : getThreadStatus === "loading" && !editTemplate ? (
                                        // case 2
                                        <div className="h-[95%] w-full max-w-[913px] m-auto pt-[50px] overflow-y-scroll">
                                            {/* {messageList.length === 0 ? ( */}
                                            <div className="w-full h-full flex justify-center items-center">
                                                {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
                                                <div class="rotating-cursor"></div>
                                            </div>
                                            {/* ) : null} */}
                                        </div>
                                    ) : (
                                        <div
                                            ref={messageListRef}
                                            className="h-[95%] w-full max-w-[913px] m-auto pt-[50px] overflow-y-scroll"
                                        >
                                            {/* case 3 */}
                                            {messageList.length === 0 &&
                                                !editTemplate &&
                                                (sendMessageStatus === "success" || sendMessageStatus === "idle") ? (
                                                <p>No messages to show</p>
                                            ) : null}

                                            {messageList.length === 0 && sendMessageStatus === "loading" ? (
                                                <div className="w-full h-[95%] flex justify-center items-center">
                                                    <div class="rotating-cursor"></div>
                                                </div>
                                            ) : null}

                                            {/* case 4 */}


                                        </div>
                                    )}


                                </>
                                <div className="w-full h-[5%]">

                                    <div className="w-full py-2 justify-center items-center bg-[#F7F7F7]">
                                        <p className="text-center text-[#757575] text-[12px] font-[500]">
                                            Always seek your attorney’s review, as AI can make mistakes. Only chats with the attorney are privileged. For details, see our
                                            <a href="https://storage.googleapis.com/inhouse-public-documents/InHouse%20Privacy%20Policy.pdf" target="blank" className="underline"> Privacy Policy.</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </section>

                    <ImageUploader
                        setImageUploader={setImageUploader}
                        imageUploader={imageUploader}
                        setFile={setFile}
                        file={filePreview}
                        textExtractionStatus={textExtractionStatus}
                    />

                    <SubscriptionModal
                        user={inhouseUser}
                        userAction={userAction}
                    />

                    <RetainerModal
                        retainermodalOpen={retainermodalOpen}
                        setRetainerModalOpen={setRetainerModalOpen}
                        chatId={chatId}
                        subscriptionType={subscriptionType}
                        getSharedId={getSharedId}
                        handleCopy={handleCopy}
                    />

                    <SubscriptionStatusRefreshModal open={showSubscriptionStatusRefreshModal} user={inhouseUser} />

                </ChatLayout>
            ) : <>
                {
                    (isLoaded && getUserDetailsStatus === "failed") ?
                        <div className="w-full h-full flex justify-center items-center">
                            <p className="text-red-500">Failed to load user details. Please refresh the page.</p>
                        </div> :
                        <div className="w-full h-full flex justify-center items-center mx-auto mt-[200px]">
                            {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
                            <div class="rotating-cursor !h-[50px]"></div>
                        </div>
                }
            </>}
        </>
    );
});

export default AskPage;
