import React, { useEffect, useState } from "react";
import { industryArray, stateOptions } from "../../components/Constants";
import { useDispatch, useSelector } from "react-redux";
import { getMessageType, saveUserProfile } from "../onboarding/onboardingSlice";
import { Spinner } from "@phosphor-icons/react";
import { getUserDetails } from "../UserAuthentication/AuthenticationSlice";
import { useNavigate } from "react-router-dom";
import oval from "../../images/oval.png";
import profile1 from "../../images/LawyerProfile1.png";
import profile2 from "../../images/LawyerProfile2.png";
import profile3 from "../../images/LawyerProfile3.png";
import clipboard from "../../images/clipboard.png";
import message from "../../images/message.png";
import compass from "../../images/compass.png";
import Carousel from "../../components/carousel/Carousel";
import "./OnboardingPage.css";

const features = [
  {
    title: "Upgrade your legal docs",
    image: clipboard,
    description:
      "Contracts grabbed from the internet are cheap but often riddled with issues. Answer a few questions to receive high quality contracts completely customized to your situation. ",
  },
  {
    title: "Answer all your legal questions",
    image: message,
    description:
      "Say goodbye to your backlog of anxiety-provoking legal questions. Lexxa is on call 24/7 to your questions big and small.",
  },
  {
    title: "Establish your legal strategy",
    image: compass,
    description:
      "InHouse can crush more than your legal tasks, your new lawyer can devise a legal strategy to match your business goals",
  },
];

const UpdateProfileComponent = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [industry, setIndustry] = useState("");
  const [businessState, setBusinessState] = useState("");
  const [showButton, setShowButton] = useState(false);
  const [firstNameMissing, setFirstNameMissing] = useState(false);
  const [lastNameMissing, setLastNameMissing] = useState(false);
  const [organizationNameMissing, setOrganizationNameMissing] = useState(false);
  const [industryMissing, setIndustryMissing] = useState(false);
  const [businessStateMissing, setBusinessStateMissing] = useState(false);
  // const [savingDataStatus, setSavingDataStatus] = useState("");
  const [notice, setNotice] = useState("");
  const [currentStep, setCurrentStep] = useState(1);

  const formDataSaveStatus = useSelector((state) => state.onboarding.status);
  // const getMessageTypeStatus = useSelector((state) => state.onboarding.getMessageTypeStatus);
  // const messageType = useSelector((state) => state.onboarding.messageType);
  const getUserDetailsStatus = useSelector((state) => state.auth.getUserDetailsStatus);
  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const error = useSelector((state) => state.onboarding.error);
  // useEffect(() => {
  //     if (!user) {
  //         navigate("/signin");
  //     }
  // }, [user]); // eslint-disable-line

  useEffect(() => {
    window.analytics.page("Onboarding page viewed");
  }, []);

  function getCookie(name) {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split("=");
      if (cookie[0] === name) {
        return decodeURIComponent(cookie[1]);
      }
    }
    return null; // Return null if cookie with given name is not found
  }

  function deleteCookie(name) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  const starterMessage = getCookie("starter-message");

  useEffect(() => {
    if (starterMessage) {
      // dispatch(getMessageType({ message: starterMessage }));
      localStorage.setItem("starterMessage", starterMessage);
      deleteCookie("starter-message");
    }
  }, [starterMessage]);

  useEffect(() => {
    if (formDataSaveStatus === "success") {
      dispatch(getUserDetails());
    } else if (formDataSaveStatus === "failed") {
      setNotice("Something went wrong. Please try again.");
    }
  }, [formDataSaveStatus]); // eslint-disable-line

  useEffect(() => {
    if (formDataSaveStatus === "success") {
      if (getUserDetailsStatus === "success") {
        if (user.first_name && user.last_name && user.organization_name && user.state) {
          if (starterMessage) {
            //   navigate(`/${messageType}`);
            // } else {
            navigate("/ask");
          }
        } else {
          if (!user.first_name) {
            setFirstNameMissing(true);
          }
          if (!user.last_name) {
            setLastNameMissing(true);
          }
          if (!user.organization_name) {
            setOrganizationNameMissing(true);
          }
          if (!user.industry) {
            setIndustryMissing(true);
          }
          if (!user.state) {
            setBusinessStateMissing(true);
          }
        }
      }
    }
  }, [getUserDetailsStatus]); // eslint-disable-line

  const handleSend = () => {

    const data = {
      email: "",
      first_name: firstName,
      last_name: lastName,
      organization_name: organizationName,
      industry: industry,
      state: businessState,
      phone_number: "",
      age_of_business: "",
      num_employees: "",
      current_legal_source: "",
      legal_work_frequency: "",
      legal_work_type: "",
      annual_legal_spend: "",
    };
    // window.analytics.trackForm(form, "Onboarding form");
    window.analytics.track("Onboarding form submitted", { user_email: user?.user_email });

    dispatch(saveUserProfile(data));
  };

  useEffect(() => {
    if (firstName.length && lastName.length && organizationName.length && industry && businessState) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [firstName, lastName, organizationName, industry, businessState]);

  return (
    <div className="content-container">
      <div className="left-half">
        <div className="try-button-container">
          {currentStep === 1 && <img src={oval} alt="oval" className="try-free-button-bg" />}
          <span className="onboarding-try">TRY FOR FREE</span>
        </div>

        <span className="onboarding-hero-text">
          {currentStep === 1
            ? "Safeguard your business with InHouse"
            : "What will you do with your own full-time InHouse Counsel?"}
        </span>
        {currentStep === 1 ? (
          <>
            <span className="onboarding-sub-text">
              Answer a few questions to be matched with your new in house lawyer and AI assistant
            </span>

            <div className="rating-container">
              <div className="profile-container">
                <div className="profile-image">
                  <img src={profile1} alt="profile" className="onboarding-image" />
                </div>
                <div className="profile-image">
                  <img src={profile2} alt="profile" className="onboarding-image" />
                </div>
                <div className="profile-image">
                  <img src={profile3} alt="profile" className="onboarding-image" />
                </div>
              </div>
              <span>+ many more 5-star attorneys</span>
            </div>
            <Carousel />
          </>
        ) : (
          <div className="features-conatiner">
            {features.map((feature, index) => (
              <div className="feature" key={index}>
                <img src={feature.image} alt="feature" className="feature-icon" />
                <div className="feature-info">
                  <span className="feature-title">{feature.title}</span>
                  <span className="feature-description">{feature.description}</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="right-half">
        <span className="text-[44px] font-[700] leading-[1] text-[#5E59FF] text-center w-full">
          Just a few questions before we get started
        </span>
        <hr className="h-[1px] w-full bg-[#DFDFDF]" />
        <form className="sign-up-form">
          <div className="form-component">
            <label htmlFor="first-name" className="form-label">
              First name
            </label>
            {firstNameMissing ? <p class="text-danger">This Field is required.</p> : null}
            <input
              id="first-name"
              type="text"
              class="form-input"
              value={firstName}
              placeholder="First Name"
              aria-label="First Name"
              onChange={(e) => {
                if (e.target.value.length > 1) {
                  setFirstNameMissing(false);
                }
                setFirstName(e.target.value);
              }}
            />
            {error && error.fieldErrors.first_name && firstName.length < 3 ? (
              <p class="text-danger">{error.fieldErrors.firstName}</p>
            ) : null}
          </div>
          <div className="form-component">
            <label htmlFor="last-name" className="form-label">
              Last name
            </label>
            {lastNameMissing ? <p class="text-danger">This Field is required.</p> : null}
            <input
              id="last-name"
              type="text"
              class="form-input"
              value={lastName}
              placeholder="Last Name"
              aria-label="Last Name"
              onChange={(e) => {
                if (e.target.value.length > 1) {
                  setLastNameMissing(false);
                }
                setLastName(e.target.value);
              }}
            />
            {error && error.fieldErrors.last_name && lastName.length < 3 ? (
              <p class="text-danger">{error.fieldErrors.last_name}</p>
            ) : null}
          </div>
          <div className="form-component">
            <label htmlFor="company-name" className="form-label">
              Company name
            </label>
            {organizationNameMissing ? <p class="text-danger">This Field is required.</p> : null}
            <input
              id="company-name"
              type="text"
              class="form-input"
              value={organizationName}
              placeholder="Company Name"
              aria-label="Company Name"
              onChange={(e) => {
                if (e.target.value.length > 1) {
                  setOrganizationNameMissing(false);
                }
                setOrganizationName(e.target.value);
              }}
              // onChange={(e) => setOrganizationName(e.target.value)}
            />
            {error && error.fieldErrors.organization_name && organizationName.length < 3 ? (
              <p class="text-danger">{error.fieldErrors.organization_name}</p>
            ) : null}
          </div>
          <div className="form-component">
            <label htmlFor="industry" className="form-label">
              Industry
            </label>
            {industryMissing ? <p class="text-danger">This Field is required.</p> : null}
            <div className="custom-select">
              <select
                id="industry"
                className="form-input"
                name="industry"
                value={industry}
                onChange={(e) => {
                  if (e.target.value.length > 1) {
                    setIndustryMissing(false);
                  }
                  setIndustry(e.target.value);
                }}

                // onChange={(e) => setIndustry(e.target.value)}
              >
                <option value="" disabled>
                  Industry type
                </option>
                {industryArray.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                <path d="M1 1L6 6L11 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>

            {error && error.fieldErrors.industry && industry === "" ? (
              <p class="text-danger">{error.fieldErrors.industry}</p>
            ) : null}
          </div>
          <div className="form-component">
            <label htmlFor="business-state" className="form-label">
              Primary State Of Business
            </label>
            {businessStateMissing ? <p class="text-danger">This Field is required.</p> : null}
            <div className="custom-select">
              <select
                id="business-state"
                className="form-input"
                name="state"
                value={businessState}
                onChange={(e) => {
                  if (e.target.value.length > 1) {
                    setBusinessStateMissing(false);
                  }
                  setBusinessState(e.target.value);
                }}
                // onChange={(e) => setBusinessState(e.target.value)}
              >
                <option value="" disabled>
                  Select State
                </option>
                {stateOptions.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                <path d="M1 1L6 6L11 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </div>

            {error && error.fieldErrors.state && businessState === "" ? (
              <p class="text-danger">{error.fieldErrors.state}</p>
            ) : null}
          </div>
          <div>
            <button
              id="next-button-step1-form"
              type="button"
              className="form-submit disabled:opacity-50 disabled:cursor-not-allowed min-w-full"
              disabled={formDataSaveStatus === "loading" || !showButton}
              onClick={(e) => {
                handleSend();
              }}
            >
              {formDataSaveStatus === "loading" ? <Spinner size={16} className="animate-spin text-black" /> : "LETS GO"}
            </button>
            {notice !== "" && (
              <div className="form-error" role="alert">
                {notice}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateProfileComponent;
