// src/components/Footer/Footer.js

import React from "react";
import "./Footer.css"; // Import the associated CSS file

const Footer = () => {
  return (
    <footer className="app-footer">
      <span>&copy; 2024, Round Table Legal AI Inc. All Rights Reserved.</span>
      <div className="footer-nav">
        <a
          target="_blank"
          href="https://docs.google.com/document/d/1E5yyNXGFf3hvYOl6h9wrbT2LFGfoecrseWU5PHu_mQo/edit?usp=sharing"
          id="privacy-link-footer"
        >
          Privacy
        </a>
        <span className="divider">|</span>
        <a
          target="_blank"
          href="https://docs.google.com/document/d/1fdNt3tEevk_Q4M0i1uZBS-njEE27xCFKONf-Gs4L_no/edit?usp=sharing"
          id="terms-link-footer"
        >
          Terms
        </a>
      </div>
    </footer>
  );
};

export default Footer;
