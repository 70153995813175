// OnboardingPage.js

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// import Carousel from "../../components/carousel/Carousel";
import StepForm from "../../components/onboarding/StepForm";
// import StepIndicator from "../../components/onboarding/StepIndicator";
import "./OnboardingPage.css";
import "../UserAuthentication/Signin.css";
import { getUserDetails } from "../UserAuthentication/AuthenticationSlice";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import useValidatePageAccess from "../../hooks/useValidatePageAccess.js";
import { useAuth } from "@clerk/clerk-react"


const OnboardingPage = () => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const getUserDetailsStatus = useSelector((state) => state.auth.getUserDetailsStatus);
  const { getToken, isLoaded, isSignedIn } = useAuth();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useValidatePageAccess();
  const handleStepChange = (step) => {
    scrollToTop();
    setCurrentStep(step);
  };

  return (
    <div className="onboarding-page">
      {isLoaded && isSignedIn ? (
        <>
          <Header />
          <div className="content-container flex flex-col">

            <div className="w-full px-5 md:w-[50%] md:px-auto mx-auto  mb-5">
              <StepForm currentStep={currentStep} handleStepChange={handleStepChange} />
            </div>
            <Footer />
          </div>
        </>
      ) : <>
        {
          (isLoaded) ?
            <div className="w-full h-full flex justify-center items-center">
              <p className="text-red-500">Failed to load user details. Please refresh the page.</p>
            </div> :
            <div className="w-full h-full flex justify-center items-center mx-auto">
              {/* <div className="spinner-grow spinner-grow-sm text-primary me-3" role="status"></div> */}
              <div class="rotating-cursor !h-[50px]"></div>
            </div>
        }
      </>}
    </div>

  );
};

export default OnboardingPage;
