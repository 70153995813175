import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetOnboardingStates, saveUserProfile, setOnboardingError } from "../../pages/onboarding/onboardingSlice";

import {

  stateOptions,
  entityOptions,
} from "../Constants";
import { Spinner } from "@phosphor-icons/react";
import { useUser } from "@clerk/clerk-react"


const StepForm = ({ currentStep, handleStepChange }) => {
  const [firstName, setFirstName] = useState("");
  const [fullName, setFullName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [industry, setIndustry] = useState("");
  const [businessPurpose, setBusinessPurpose] = useState("");
  const [entityStructure, setEntityStructure] = useState("");
  const [businessState, setBusinessState] = useState("");
  const [incorporationState, setIncorporationState] = useState("");
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [profileUpdationError, setProfileUpdationError] = useState(false);

  const [notice, setNotice] = useState("");
  const [savingDataStatus, setSavingDataStatus] = useState("");
  const [showLoading, setShowLoading] = useState(false);


  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userType = useSelector((state) => state.auth.userType);
  const formDataSaveStatus = useSelector((state) => state.onboarding.status);
  const error = useSelector((state) => state.onboarding.error);


  const { user } = useUser();

  useEffect(() => {
    if (userType === "lawyer") {
      navigate("/lawyer");
    }
  }, [userType]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    window.analytics.page("Onboarding page viewed");
  }, []);


  useEffect(() => {
    if (user) {
      setFullName(user.fullName)
      setOrganizationName(user.unsafeMetadata.organization_name);
      setBusinessState(user?.unsafeMetadata?.state ?? "");
      setIncorporationState(user?.unsafeMetadata?.state_incorporation ?? null);
      setEntityStructure(user?.unsafeMetadata?.entity_type ?? null);
      setBusinessPurpose(user?.unsafeMetadata?.business_purpose);
    }
  }, [user]);

  function getCookie(name) {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split("=");
      if (cookie[0] === name) {
        return decodeURIComponent(cookie[1]);
      }
    }
    return null; // Return null if cookie with given name is not found
  }

  function deleteCookie(name) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  const starterMessage = getCookie("starter-message");

  useEffect(() => {
    if (starterMessage) {
      // dispatch(getMessageType({ message: starterMessage }));
      localStorage.setItem("starterMessage", starterMessage);
      deleteCookie("starter-message");
    }
  }, [starterMessage]);

  useEffect(() => {
    const spaceIndex = fullName?.indexOf(" ");
    if (spaceIndex && spaceIndex !== -1) {
      const firstName = fullName.slice(0, spaceIndex);
      const lastName = fullName.slice(spaceIndex + 1);
      setFirstName(firstName);
      setLastName(lastName);
    } else {
      console.log("setting last name as blank")
      setFirstName(fullName || "");
      setLastName(""); // Reset last name
    }
  }, [fullName]);

  const handleSend = async () => {
    setShowLoading(true)
    setProfileUpdated(false)
    setProfileUpdationError(false);
    const requiredFields = [fullName, organizationName, businessPurpose, businessState, incorporationState, entityStructure];
    const fieldNames = ["first_name", "organization_name", "business_purpose", "state", "state_incorporation", "entity_type"];
    // debugger;
    const isFormValid = requiredFields.every((field) => !(!field));
    if (!isFormValid) {
      setProfileUpdationError(true);
      setShowLoading(false)
      const fieldErrors = {}
      for (let i = 0; i < requiredFields.length; i++) {
        if (!requiredFields[i]) {
          fieldErrors[fieldNames[i]] = "This field is required";
        }
      }
      dispatch(setOnboardingError({ "fieldErrors": fieldErrors }));
      // dispatch(setOnboardingError({ fieldErrors: { firstName: "This field is required" } }));
      return
    }
    // console.log(user?.unsafeMetadata)
    user.update({
      unsafeMetadata: {
        organization_name: organizationName,
        industry: industry,
        state: businessState,
        phone_number: user?.primaryPhoneNumber?.phoneNumber ? user?.primaryPhoneNumber?.phoneNumber : "",
        email: user?.primaryEmailAddress?.emailAddress,
        phone: user?.primaryPhoneNumber?.phoneNumber ? user?.primaryPhoneNumber?.phoneNumber : "",
        age_of_business: user?.unsafeMetadata?.age_of_business,
        num_employees: user?.unsafeMetadata?.num_employees,
        current_legal_source: user?.unsafeMetadata?.current_legal_source,
        legal_work_frequency: user?.unsafeMetadata?.legal_work_frequency,
        legal_work_type: user?.unsafeMetadata?.legal_work_type,
        annual_legal_spend: user?.unsafeMetadata?.annual_legal_spend,
        business_purpose: businessPurpose,
        entity_type: entityStructure,
        state_incorporation: incorporationState,
      },
      "firstName": firstName,
      "lastName": lastName,
    })


    const data = {
      email: user?.primaryEmailAddress?.emailAddress,
      first_name: firstName,
      last_name: lastName,
      organization_name: organizationName,
      industry: industry,
      state: businessState,
      phone_number: user?.primaryPhoneNumber?.phoneNumber ? user?.primaryPhoneNumber?.phoneNumber : "",
      age_of_business: user?.unsafeMetadata?.age_of_business || "",
      num_employees: user?.unsafeMetadata?.num_employees || "",
      current_legal_source: user?.unsafeMetadata?.current_legal_source ? user?.unsafeMetadata?.current_legal_source : "",
      legal_work_frequency: user?.unsafeMetadata?.legal_work_frequency,
      legal_work_type: user?.unsafeMetadata?.legal_work_type ? user?.unsafeMetadata?.legal_work_type : "",
      annual_legal_spend: user?.unsafeMetadata?.annual_legal_spend,
      state_incorporation: incorporationState,
      entity_type: entityStructure,
      business_purpose: businessPurpose,
    };
    console.log(data)
    dispatch(saveUserProfile(data));
  };

  useEffect(() => {
    setSavingDataStatus(formDataSaveStatus);
  }, [formDataSaveStatus]);

  useEffect(() => {
    if (savingDataStatus === "success" || savingDataStatus === "failed") {
      setShowLoading(false);
      navigate("/ask")
    }
  }, [savingDataStatus]);


  // useEffect(() => {
  //   if (firstName?.length && organizationName?.length && incorporationState && businessState && entityStructure) {
  //     setShowButton(true);
  //   } else {
  //     setShowButton(false);
  //   }
  // }, [firstName, organizationName, incorporationState, businessState, entityStructure]);

  return (
    <div style={{ width: "100%" }} className="py-5">
      <h2 className="text-[#0D0D0D] text-center text-[40px] md:text-[40px] leading-[40px] md:leading-[40px] font-[FoundersGroteskBold] font-[700]">
        Enter your information to personalize the AI
      </h2>
      {/* {currentStep === 1 ? ( */}

      <form className="sign-up-form bg-[#fff] w-full md-[724px] my-3 md:!p-5 p-3 px13-gap">

        <div className="form-component px3-gap">
          <label htmlFor="full-name" className="form-label">
            Name
          </label>
          <input
            id="full-name"
            type="text"
            className="form-input" // Using className instead of class
            value={fullName}
            placeholder="First Name & Last Name"
            aria-label="full Name"
            onChange={(e) => {
              setFullName(e.target.value);
            }}
          />

          {error && error.fieldErrors.first_name && fullName?.length < 3 ? (
            <p class="text-danger">{error.fieldErrors.first_name}</p>
          ) : null}
        </div>

        <div className="form-component px3-gap">
          <label htmlFor="company-name" className="form-label">
            Company
          </label>
          <input
            id="company-name"
            type="text"
            class="form-input"
            value={organizationName}
            placeholder="Company Name"
            aria-label="Company Name"
            onChange={(e) => setOrganizationName(e.target.value)}
          />
          {error && error.fieldErrors.organization_name && (!organizationName || organizationName?.length < 3) ? (
            <p class="text-danger">{error.fieldErrors.organization_name}</p>
          ) : null}
        </div>

        <div className="form-component px3-gap">
          <div className="form-label-container">
            <label htmlFor="businessPurpose" className="form-label">
              Purpose of business
            </label>
          </div>
          <input
            type="businessPurpose"
            className="form-input"
            id="businessPurpose"
            aria-describedby="businessPurposeHelp"
            placeholder="We sell electric toothbrushes for toddlers"
            value={businessPurpose}
            onChange={(e) => setBusinessPurpose(e.target.value)}
          ></input>
          {error && error.fieldErrors.business_purpose && !businessPurpose ? (
            <p class="text-danger">{error.fieldErrors.business_purpose}</p>
          ) : null}
        </div>

        <div className="form-component px3-gap">
          <label htmlFor="business-state" className="form-label">
            Primary State Of Business
          </label>
          <div className="custom-select">
            <select
              id="business-state"
              className="form-input"
              name="state"
              value={businessState}
              onChange={(e) => setBusinessState(e.target.value)}
            >
              <option value="" disabled>
                Select State
              </option>
              {stateOptions.map((state, index) => (
                <option key={index} value={state}>
                  {state}
                </option>
              ))}
            </select>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
              <path d="M1 1L6 6L11 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>

          {error && error.fieldErrors.state && !businessState ? (
            <p class="text-danger">{error.fieldErrors.state}</p>
          ) : null}
        </div>

        <div className="form-component px3-gap">
          <label htmlFor="incorporation-state" className="form-label">
            State of incorporation
          </label>
          <div className="custom-select">
            <select
              id="incorporation-state"
              className="form-input"
              name="state"
              value={incorporationState}
              onChange={(e) => setIncorporationState(e.target.value)}
            >
              <option value="" disabled>
                Select State
              </option>
              {stateOptions.map((state, index) => (
                <option key={index} value={state}>
                  {state}
                </option>
              ))}
            </select>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
              <path d="M1 1L6 6L11 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>

          {error && error.fieldErrors.state_incorporation && !incorporationState ? (
            <p class="text-danger">{error.fieldErrors.state_incorporation}</p>
          ) : null}
        </div>
        <div className="form-component px3-gap">
          <label htmlFor="entity-structure" className="form-label">
            Entity structure
          </label>
          <div className="custom-select">
            <select
              id="entity-structure"
              className="form-input"
              name="entity"
              value={entityStructure}
              onChange={(e) => setEntityStructure(e.target.value)}
            >
              <option value="" disabled>
                Select Entity
              </option>
              {entityOptions.map((entity, index) => (
                <option key={index} value={entity}>
                  {entity}
                </option>
              ))}
            </select>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
              <path d="M1 1L6 6L11 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>

          {error && error.fieldErrors.entity_type && (!entityStructure) ? (
            <p class="text-danger">{error.fieldErrors.entity_type}</p>
          ) : null}
        </div>

        <div className="w-full">
          <button
            id="next-button-step1-form"
            type="button"
            className="form-submit disabled:opacity-50 disabled:cursor-not-allowed min-w-full"
            disabled={showLoading}
            onClick={(e) => {
              handleSend();
            }}
          >
            {showLoading ? (
              <Spinner size={16} className="animate-spin text-black" />
            ) : (
              "Submit"
            )}
          </button>
          {notice !== "" && (
            <div className="form-error" role="alert">
              {notice}
            </div>
          )}
        </div>
      </form>

    </div>
  );
};

export default StepForm;
